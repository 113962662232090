function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/rad/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RADRoutes = [
  {
    path: '/rad/dashboard',
    component: view('Dashboard'),
    name: 'rad.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/rad/pending-approvals',
    component: view('PendingApprovals'),
    name: 'rad.pending-approvals',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Pending Approvals',
          active: true
        }
      ]
    }
  },
  {
    path: '/rad/reports/approval-histories',
    component: view('ApprovalHistories'),
    name: 'rad.approval-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Approval Histories',
          active: true
        }
      ]
    }
  }
]

export default RADRoutes
